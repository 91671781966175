.contact{
    height: 100vh;
    position: relative;
}

.contact-bg{
    width: 20px;
    height: 100%;
    background-color: #007CC7;
    position: absolute;
}

.contact-wrapper{
    padding: 50px 100px;
    display: flex;
}

.contact-left{
    flex: 1;
}

.contact-right{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact-title{
    font-size: 60px;
    width: 80%;
}

.contact-info-item{
    display: flex;
    align-items: center;
    margin: 50px 0px;
    font-weight: 300;
}

.contact-icon{
    width: 30px;
    height: 30px;
    margin-right: 20px;
}

.contact-desc{
    font-weight: 200;
    font-size: 40px;
}

form{
    margin-top: 20px;
}

input{
    width: 100%;
    height: 50px;
    border: none;
    border-bottom: 1px solid black;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
}

textarea{
    width: 100%;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
}

button{
    border: none;
    padding: 15px;
    background-color: #007CC7;
    color: white;
    font-weight: 500;
    cursor: pointer;
    border-radius: 15px;
    transition: 0.3s;
}
button:hover{
    background-color: rgb(0, 97, 158);
}



ul li {
    list-style: none;
  }
  
  ul li a {
    width: 80px;
    height: 80px;
    background-color: #eaeaea;
    text-align: center;
    line-height: 80px;
    font-size: 35px;
    margin: 0 10px;
    display: block;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    border: 3px solid #fff;
    z-index: 1;
  }
  
  ul li a .icon {
    position: relative;
    color: #262626;
    transition: .5s;
    z-index: 3;
  }
  
  ul li a:hover .icon {
    color: #fff;
    transform: rotateY(360deg);
  }
  
  ul li a:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f00;
    transition: .5s;
    z-index: 2;
  }
  
  ul li a:hover:before {
    top: 0;
  }
  
  ul li:nth-child(1) a:before{
    background: #0077b5;
  }

  @media screen and (max-width:830px) {
    .contact-wrapper{
        padding: 50px 30px;
    }
    }

@media screen and (max-width: 480px){
    .contact-wrapper {
flex-direction: column;
padding: 0px 50px;
    }

    .contact-title{
        font-size: 30px;
    }

    .contact-info-item{
        margin: 20px 0px;
        width: 100%;
    }

    .contact-desc{
        display: none;
    }

    form{
        margin-top: 0px;
        display: flex;
        flex-wrap: wrap
    }

    button{
        margin-top: 10px;
    }

}