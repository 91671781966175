.intro{
    display: flex;
    height: 100vh;
   
}

.intro-intro{
    font-size: 30px;
    font-weight: 300;
}

.intro-name{
    font-size: 60px;
}

.intro-title{
height: 50px;
overflow: hidden;
}

.intro-title-wrapper{
    height: 100%;
    animation: move 5s ease-in-out infinite alternate;
}

@keyframes move {
    50% {
transform: translateY(-50px);
    }
    100% {
        transform: translateY(-100px);
    }
    
}

.intro-title-item{
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    color: #007CC7;
    display: flex;
    align-items: center;
    }

.intro-left{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.intro-right{
    flex: 1;
    position: relative;
}

.intro-left-wrapper{
    padding: 50px;
    height: 35%;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.intro-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
}

.intro-bg{
    clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 25% 50%, 0% 0%);
    background-color: #007CC7;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
}

.intro-scroll {
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 20px;
    transition: transform 250ms;
}

.intro-scroll:hover {
    transform: translateY(-10px);
}

@media screen and (max-width: 480px){
    .intro {
flex-direction: column;
    }

    .intro-left-wrapper{
        padding: 10px;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
    }

    .intro-desc{
        display: none;
    }

    .toggle {
        right: 115px;
    }
}