.about {
    height: 100vh;
    display: flex;
    align-items: center;
}

.about-left{
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
}

.about-right{
    flex:1;
    padding: 0px 50px;
}

.about-card.bg{
    position: absolute;
    top: 50px;
    left: 50px;
    background-color: #333;
}

.about-card{
    width: 60%;
    height: 70vh;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
}

.about-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.about-award-img {
    width: 120px;
    height: 120px;
    border-radius: 20px;
}

.about-award{
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-title{
    font-weight: 400;
}

.about-subtitle{
    margin: 20px 0px;
}

.about-desc{
    font-weight: 300;
}
@media screen and (max-width:830px) {
    .about-card.bg{
        top: 128px;
        
    }
    .about-card{
        height: 50vh;
    }
    
}

@media screen and (max-width:480px) {
    .about{
        flex-direction: column;
        text-align: center;
        margin-top: 50px;
    }

    .about-left{
        width: 100%;
    }

    .about-card{
        height: 30vh;
    }

    .about-card.bg, .about-award {
        display: none;
    }

    .about-right{
        padding: 20px;
    }
}